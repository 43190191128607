import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const BeYou = () =>
    // { link }
    {
        return (
            <Fragment>
                <div className='text-center mt-4'>
                    <p className='primary-text fw-900'> Be you!</p>
                </div>
                <p>
                    {' '}
                    Lenses.io is proud to be an equal opportunity workplace. We celebrate
                    difference, support it, and thrive on a diverse team that represents a variety
                    of backgrounds, perspectives, beliefs and experiences. The more inclusive we
                    are, the better we are, and our work will be.
                </p>

                <p>
                    If you have a disability or special need that requires accommodation at any
                    point through the interview process, please do not hesitate to let us know.
                </p>
                <div>
                    <p className='primary-text'>
                        We’d love to hear from you! <br />
                        <a className='primary-text' href='mailto:careers@lenses.io'>
                            careers@lenses.io
                        </a>
                    </p>
                </div>
                <div>
                    {/* Temporary disabling Workable links */}
                    {/* {link && (
          <div className="roll-button mb-4">
            <a href={link} className="bg-red text-white">
              <span data-hover="Apply through Workable">
                Apply through Workable
              </span>
            </a>
          </div>
        )} */}
                </div>
            </Fragment>
        )
    }

export default BeYou

BeYou.propTypes = {
    link: PropTypes.string,
}
