import React, { useState, useEffect } from 'react'
import Layout from '../../components/Layout'
import WhoWeAre from '../../components/careers/WhoWeAre'
import PartOfLenses from '../../components/careers/PartOfLenses'
import BeYou from '../../components/careers/BeYou'
import HeaderSEO from '../../components/partials/HeaderSEO'
import { Helmet } from 'react-helmet'

interface PageSEO {
  description: Description
  keywords: Keywords
  title: Title
  oGimage: OGimage
}

interface OGimage {
  fluid: Fluid
}
interface Fluid {
  src: string
}
interface Keywords {
  keywords: string
}
interface Description {
  description: string
}
interface Title {
  title: string
}

interface StateProps {
  LocationState: LocationData
}

interface LocationData {
  roleLocation: string
  link: string
}

const developer_advocate: React.FC<StateProps> = () => {
  const [locationData, setLocationData] = useState<LocationData>({
    roleLocation: 'London, UK',
    link: 'https://apply.workable.com/lensesio/j/F60A95303A/',
  })
  const { roleLocation, link } = locationData

  useEffect(() => {
    var url_string = window.location.href
    var url = new URL(url_string)
    var location = url.searchParams.get('location')
    if (location == 'us') {
      setLocationData({
        roleLocation: 'New York, NY',
        link: 'https://apply.workable.com/lensesio/j/E9C43A3CD7/',
      })
    }
  }, [])

  // Matching to HeaderSEO format
  const pageSEO: PageSEO = {
    description: {
      description:
        'We are looking for a Developer Advocate. Apply to join our team!',
    },
    keywords: {
      keywords: 'Developer Advocate,Lenses careers,Hiring,Job opening',
    },
    oGimage: {
      fluid: {
        src: '//images.ctfassets.net/tnuaj0t7r912/70AJ9c0DcWQCC5qB84Jhvq/f19afa92bc7cf1435a21a1585ecb8fd6/developer-advocate.jpg',
      },
    },
    title: {
      title: 'Developer Advocate',
    },
  }

  return (
    <Layout>
      <HeaderSEO pageSEO={pageSEO} />
      <Helmet>
        <link
          rel="canonical"
          href="https://lenses.io/careers/developer-advocate/"
        />
      </Helmet>
      <div className=" bg-dark py-5 my-5"></div>
      <br />
      <br />
      <div className="container-1 mb-5">
        <div className="card offset-md-2 col-md-8 col-sm-12">
          <div className="card-body pl-4 pr-4">
            <section className="section section--header">
              <div className="roll-button">
                <a
                  href="/careers/#openpositions"
                  className="bg-red text-white btn-xs mr-0 border-0 all-jobs-negative-margin"
                >
                  <i className="fa fa-arrow-left mr-1"></i>
                  <span data-hover="All jobs">All jobs</span>
                </a>
              </div>
            </section>
          </div>
          <h1 className="h3-like">Developer Advocate</h1>
          <p className="meta">{roleLocation}</p>
          <section className="section section--text">
            <WhoWeAre />

            <h5 className="pt-3">What your day-to-day looks like:</h5>

            <ul className="pl-4 ">
              <li>
                Presenting at industry meetups, conferences and community events
              </li>
              <li>Contributing to Lenses’ open-source projects</li>
              <li>
                Creating technical material such as blogs and videos that help
                educate developers onLenses, Kafka and other open-source
                technologies
              </li>
              <li>
                Maximizing the reach and the impact of this content, through
                social media, public forums and special events
              </li>
              <li>
                Nurturing the community, with engaging activities and content
              </li>
              <li>
                Building and growing relationships with key members of the
                community
              </li>
              <li>Engaging with customers and prospects in the field</li>
              <li>
                Feeding back ideas from the community to the Lenses engineering
                team
              </li>
            </ul>
            <h5 className="pt-3">What you will bring:</h5>

            <ul className="pl-4 ">
              <li>Experience in working in a software/data engineering team</li>
              <li>Track record of developing education content</li>
              <li>Development of video content</li>
              <li>Great literacy skills and articulate in presentation</li>
              <li>Creative marketing ideas and concepts</li>
              <li>
                Excellent knowledge of Apache Kafka and building streaming
                applications in languages and frameworks such Kafka Streams,
                Python and Go
              </li>
              <li>
                Very good knowledge of other open-source data technologies and
                cloud infrastructure
              </li>
            </ul>

            <h5 className="pt-3">Passionate about:</h5>

            <ul className="pl-4 ">
              <li>Engineering & streaming data technologies</li>
              <li>Sharing knowledge</li>
              <li>Customer success</li>
              <li>Being part of a community</li>
              <li>Sharing success with others</li>
              <li>Inspiring people</li>
            </ul>

            <PartOfLenses />
          </section>

          <section>
            <BeYou link={link} />
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default developer_advocate
