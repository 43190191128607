import React, { Fragment } from 'react'

const partOfLenses = () => {
    return (
        <Fragment>
            <h5 className='pt-3'>Being Part of the Lenses.io team:</h5>

            <ul className='pl-4 '>
                <li>
                    Lenses.io is part of Celonis, the Decacorn in the enterprise software industry,
                    giving you the opportunity to compete on the world stage.
                </li>
                <li>
                    You will be exposed to the front-line of streaming technology applied globally.
                    This will give you unique insights that are immensely valuable and rare.
                </li>
                <li>
                    We are ready to rock the market and offer a competitive salary, generous holiday
                    allowance and strong global and local benefits.
                </li>
                <li>
                    You will be joining an awesome, constantly evolving and vibrant environment.
                </li>
                <li>
                    Play a significant role in the development of the company and attain the
                    opportunity for rapid career progression and personal growth.
                </li>
                <li>
                    Work with exceptional, diverse, professional people at the forefront of their
                    fields.
                </li>
            </ul>
        </Fragment>
    )
}

export default partOfLenses
