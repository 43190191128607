import React, { Fragment } from 'react'

const whoWeAre = () => {
    return (
        <Fragment>
            <h5 className='pt-3 fw-600'>Who are we?</h5>
            <p>
                At{' '}
                <a href='/' target='_blank' rel='noopener noreferrer'>
                    Lenses.io
                </a>{' '}
                we are on a mission to democratize streaming data, helping businesses to operate in
                real-time.
            </p>
            <p>
                <span className='primary-text'> Our technology </span> allows organizations to
                practice DataOps, a new and disruptive way of consuming, processing and moving data.{' '}
                <span className='primary-text'> Our product</span>, Lenses, delivers an amazing
                developer experience for building & operating streaming data applications. It makes
                working with leading open-source technologies such as Apache Kafka & Kubernetes
                accessible for more than ten thousand engineers, and operationalizes them for both
                enterprises including Daimler-Benz and startups such as Babylon Health.
            </p>
            <p>
                If you&apos;re curious, love challenges, and have a great sense of humour then
                Lenses is the place for you!
            </p>
        </Fragment>
    )
}

export default whoWeAre
